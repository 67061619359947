import http from '@/services/http.js';
import routes from '@/services/env-routes.js';

export function getBonusTags(tenantUuid) {
  const instance = localStorage.getItem('instance');
  const languageParam = localStorage.getItem('language');

  return http({
    method: 'GET',
    url: `${routes.aioCore}/tenants/${tenantUuid}/instances/${instance}/languages/${languageParam}/bonus-tags`,
  });
}

export function getBonuses(tenantUuid) {
  const instance = localStorage.getItem('instance');
  const languageParam = localStorage.getItem('language');

  return http({
    method: 'GET',
    url: `${routes.aioCore}/tenants/${tenantUuid}/instances/${instance}/languages/${languageParam}/bonuses`,
  });
}

export function getCurrencies(tenantUuid) {
  const instance = localStorage.getItem('instance');
  const languageParam = localStorage.getItem('language');

  return http({
    method: 'GET',
    url: `${routes.aioCore}/tenants/${tenantUuid}/instances/${instance}/languages/${languageParam}/currencies`,
  });
}
