<template>
  <v-app v-if="!loading">
    <RouterView />
    <div class="alert-container">
      <v-alert
        v-for="[id, notification] of notificationsStore"
        :key="id"
        class="included"
        closable
        :text="notification.text"
        :type="notification.type"
        @click:close.stop
        @click="notification.action" />
    </div>
  </v-app>
</template>

<script setup>
import { onMounted, provide, reactive, readonly, ref } from 'vue';
import { RouterView } from 'vue-router';
import { languageCodesKey, tenantDataKey, tenantsListKey, translationsKey } from './constants';
import { getActiveFeeds, getActiveFeedTypes } from './services/api/feeds';
import { getEnabledLanguages, getTenantDefaultLanguage } from './services/api/languages';
import { getSupplementalNameTypes } from './services/api/supplementalNameTypes';
import { getTranslations } from './services/translations';
import { mapLangugageCodes } from '@/services/formatter';
import { getUserTenants } from '@/services/api/tenants';
import { addNotification, notificationsStore } from '@/stores/notifications.store.js';
import { getCurrencies } from '@/services/api/bonus.js';

const translations = ref({});
const languageCodes = ref({});

const tenantsList = ref([]);
const loading = ref(false);

const tenantData = reactive({
  enabledLanguages: [],
  defaultLanguage: {},
  activeFeedTypes: [],
  activeFeeds: [],
  supplementalNameTypes: [],
  supportedCurrencies: [],
});

provide(tenantDataKey, readonly(tenantData));
provide(translationsKey, readonly(translations));
provide(languageCodesKey, readonly(languageCodes));
provide(tenantsListKey, tenantsList);

function updateNotificationOptions(data) {
  addNotification(data);
}

provide('notificationOptions', {
  updateNotificationOptions,
});

async function fetchTenants() {
  try {
    const tenants = await getUserTenants();
    tenantsList.value = tenants.data;
    return tenants.data;
  } catch (e) {
    return e;
  }
}

async function setTenantData() {
  loading.value = true;
  if (!tenantsList.value?.length) {
    await fetchTenants();
    const firstAvailableTenant = tenantsList.value[0]?.uuid;

    const currentTenantUuid =
      new URLSearchParams(window.location.search).get('tenantUuid') ||
      localStorage.getItem('tenantUuid');

    const tenantUuidToUse = tenantsList.value.some((tenant) => currentTenantUuid === tenant.uuid)
      ? currentTenantUuid
      : firstAvailableTenant;

    localStorage.setItem('tenantUuid', tenantUuidToUse);
    window.tenantUuid = tenantUuidToUse;
  }

  const tenant = tenantsList.value.find((tenant) => tenant.uuid === window.tenantUuid);
  window.tenantName = tenant.name;
  window.tenantId = tenant.id;

  Promise.all([
    getEnabledLanguages(),
    getTenantDefaultLanguage(),
    getSupplementalNameTypes(),
    getActiveFeedTypes(),
    getActiveFeeds(),
    getTranslations({ language: 'en' }),
    getCurrencies(window.tenantUuid),
  ])
    .then((res) => {
      tenantData.enabledLanguages = !res[0].error ? res[0].data : [];
      tenantData.defaultLanguage = !res[1].error ? res[1].data : {};
      tenantData.supplementalNameTypes = !res[2].error ? res[2].data : [];
      tenantData.activeFeedTypes = !res[3].error ? res[3].data : [];
      tenantData.activeFeeds = !res[4].error ? res[4].data : [];
      translations.value = res[5] || [];

      const currencies = res[6].data.data?.map((value) => value.alphaCode);
      tenantData.supportedCurrencies = currencies ?? [];
      tenantData.currenciesData = res[6].data.data;

      if (tenantData.enabledLanguages.length) {
        languageCodes.value = mapLangugageCodes(tenantData.enabledLanguages);
      }

      loading.value = false;
      return true;
    })
    .catch((error) => {
      console.error(error);
      updateNotificationOptions({
        visible: true,
        type: 'error',
        text: 'Something went wrong with fetching data. Please try again later.',
      });
      return error;
    });
}

function resetTenantData() {
  tenantData.value = {
    enabledLanguages: [],
    defaultLanguage: {},
    activeFeedTypes: [],
    activeFeeds: [],
    supplementalNameTypes: [],
    supportedCurrencies: [],
  };

  tenantsList.value = [];
}

provide('setTenantData', setTenantData);
provide('resetTenantData', resetTenantData);

onMounted(() => {
  localStorage.setItem('instance', '0');
  localStorage.setItem('language', 'en');

  setTenantData();
});
</script>

<style lang="scss">
:root {
  --v-theme-on-surface: 123, 123, 123;
}
// Remove this once filter component is created
.filters {
  gap: 2rem;

  & > div:first-child {
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  & > div:last-child {
    align-self: flex-end;
  }

  &__status {
    .v-field__input {
      min-width: 80px;
    }
  }

  &__live-status,
  &__language {
    .v-field__input {
      min-width: 110px;
    }
  }

  &__prematch-status,
  &__games {
    .v-field__input {
      min-width: 160px;
    }
  }

  &__feeds {
    .v-field__input {
      min-width: 170px;
    }
  }
}

.v-menu {
  & > .v-overlay__content {
    & > .v-list {
      overflow-x: overlay !important;

      &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
        border-radius: 0.25rem;
        opacity: 0;
        transition: all 0.3s ease;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(#ccc, 0.3);
        border-radius: 0.25rem;
        transition: all 1.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(#aaa, 0.5);
      }
    }
  }
}

.alert-container {
  position: fixed;
  z-index: 2450;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
