const apiRoutes = {
  production: {
    aioCore: 'https://aio-core-admin.de-2.nsoft.cloud',
    bets: 'https://aio-bet-list-rest.de-2.nsoft.cloud',
    betslip: 'https://aio-betslip-rest.de-2.nsoft.cloud',
    limits: 'https://aio-limit-rest.de-2.nsoft.cloud',
    tc: 'https://frasier.de-2.nsoft.cloud',
    tenant: 'https://sxg-int-tenant-manager.de-2.nsoft.cloud',
    guards: 'https://int-sxg-guards.de-2.nsoft.cloud',
    offerDistribution: 'https://aio-offer-distribution.de-2.nsoft.cloud',
    customBet: 'https://aio-custom-bet-rest.de-2.nsoft.cloud',
  },
  staging: {
    aioCore: 'https://aio-core-admin.staging.de-2.nsoft.cloud',
    bets: 'https://aio-bet-list-rest.staging.de-2.nsoft.cloud',
    betslip: 'https://aio-betslip-rest.staging.de-2.nsoft.cloud',
    limits: 'https://aio-limit-rest.staging.de-2.nsoft.cloud',
    tc: 'https://frasier.staging.de-2.nsoft.cloud',
    tenant: 'https://int-sxg-tenant-manager.staging.de-2.nsoft.cloud',
    guards: 'https://int-sxg-guards.staging.de-2.nsoft.cloud',
    offerDistribution: 'https://aio-offer-distribution.staging.de-2.nsoft.cloud',
    customBet: 'https://aio-custom-bet-rest.staging.de-2.nsoft.cloud',
  },
};

export default apiRoutes[appEnvironment] ?? apiRoutes.staging;
